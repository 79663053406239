import React, { FC } from "react";
import { Collapse, Navbar, NavbarToggler, NavItem } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import "./NavMenu.css";
import { useAuth, useUser } from "../auth/authContext";
import BuildVersion from "./BuildVersion";

const logo = (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 289.33 75.72"
  >
    <polygon
      fill="#ff9015"
      points="42.23 20.5 32.78 15.04 23.34 20.5 32.78 25.95 42.23 20.5"
    />
    <polygon points="15.47 24.73 9.95 27.81 32.78 40.99 65.57 22.07 65.57 18.93 32.78 0 0 18.93 0 39.42 32.78 58.36 60.14 42.56 60.14 53.66 32.78 69.45 0 50.41 0 56.79 32.78 75.72 65.57 56.79 65.57 33.09 32.78 52.09 5.43 36.29 5.43 22.07 32.78 6.27 57.43 20.5 32.78 34.72 15.47 24.73" />
    <path
      d="M140.18,61a2.27,2.27,0,0,0,.79.87,6.49,6.49,0,0,0,1.6.75,26.4,26.4,0,0,0,2.57.71,27,27,0,0,1,3.05,1,7.52,7.52,0,0,1,2.2,1.29,5.11,5.11,0,0,1,1.33,1.78,5.84,5.84,0,0,1,.45,2.38,6.48,6.48,0,0,1-.57,2.78,5.82,5.82,0,0,1-1.59,2,7.05,7.05,0,0,1-2.45,1.28,10.85,10.85,0,0,1-3.15.44,13.81,13.81,0,0,1-4.83-.86,13.32,13.32,0,0,1-4.27-2.63l2.31-2.74a13.58,13.58,0,0,0,3.21,2.1,8.82,8.82,0,0,0,3.68.73,4.76,4.76,0,0,0,2.82-.75,2.37,2.37,0,0,0,1-2,2.71,2.71,0,0,0-.2-1.06,2.21,2.21,0,0,0-.75-.86,6.55,6.55,0,0,0-1.5-.73,25.66,25.66,0,0,0-2.49-.72,26.64,26.64,0,0,1-3.1-.9,7.72,7.72,0,0,1-2.28-1.25,4.87,4.87,0,0,1-1.41-1.81,6.19,6.19,0,0,1-.48-2.58,6,6,0,0,1,.55-2.6,6,6,0,0,1,1.53-2,7,7,0,0,1,2.34-1.3,9.56,9.56,0,0,1,3-.45,12.71,12.71,0,0,1,4.32.7,13.07,13.07,0,0,1,3.6,2l-2.06,2.9a13.9,13.9,0,0,0-3-1.67,8.1,8.1,0,0,0-3-.57,4,4,0,0,0-2.62.76,2.33,2.33,0,0,0-.93,1.86A2.57,2.57,0,0,0,140.18,61Z"
      transform="translate(-55.33 -27.64)"
    />
    <path
      d="M162.05,66.78V76h-3.8V54.16h3.8v9.07h10.47V54.16h3.8V76h-3.8v-9.2Z"
      transform="translate(-55.33 -27.64)"
    />
    <path
      d="M204.11,76h-4.05l-2.21-5.27H187.53L185.29,76h-3.93L191,54h3.55ZM192.67,58.59l-3.74,8.72h7.51Z"
      transform="translate(-55.33 -27.64)"
    />
    <path
      d="M223.16,76l-5.38-7.61H213V76h-3.8V54.16h9.72a11.17,11.17,0,0,1,3.38.49A7.33,7.33,0,0,1,224.82,56a5.94,5.94,0,0,1,1.61,2.18,7.78,7.78,0,0,1,.17,5.33,6.33,6.33,0,0,1-2.71,3.3,8.47,8.47,0,0,1-2.11.89L227.67,76Zm-1.25-17.43a5.34,5.34,0,0,0-3.35-.93H213V65h5.62a5,5,0,0,0,3.28-1,3.3,3.3,0,0,0,1.22-2.7A3.23,3.23,0,0,0,221.91,58.55Z"
      transform="translate(-55.33 -27.64)"
    />
    <path
      d="M249.42,57.62H237.08v5.61H248v3.46H237.08v5.83h12.5V76h-16.3V54.16h16.14Z"
      transform="translate(-55.33 -27.64)"
    />
    <path
      className="cls-1"
      d="M271.65,64.23a6.6,6.6,0,0,1-1.85,2.24,8.13,8.13,0,0,1-2.73,1.36,11.85,11.85,0,0,1-3.3.45h-5.3V76H256V54.16h8.16a11,11,0,0,1,3.35.49A7.71,7.71,0,0,1,270.11,56a5.82,5.82,0,0,1,1.65,2.18,7,7,0,0,1,.58,2.91A6.71,6.71,0,0,1,271.65,64.23Zm-3.38-6.59a7,7,0,0,0-4.27-1.2h-5.53V66h5.41a8.24,8.24,0,0,0,2.45-.35,5.61,5.61,0,0,0,1.9-1,4.1,4.1,0,0,0,1.22-1.53,4.41,4.41,0,0,0,.43-2A4.18,4.18,0,0,0,268.27,57.64Z"
      transform="translate(-55.33 -27.64)"
    />
    <path
      className="cls-1"
      d="M296.45,76H293.8l-2.56-5.77H279.37L276.79,76h-2.53l9.94-22h2.31ZM285.32,56.91l-5,11.12h9.94Z"
      transform="translate(-55.33 -27.64)"
    />
    <path
      className="cls-1"
      d="M317.05,76l-6.32-8.48h-6.24V76H302V54.16h9.38a10.65,10.65,0,0,1,3.26.47A7.14,7.14,0,0,1,317.14,56a5.77,5.77,0,0,1,1.59,2,6.14,6.14,0,0,1,.56,2.67,6.52,6.52,0,0,1-.43,2.45A5.63,5.63,0,0,1,317.63,65a6.81,6.81,0,0,1-1.89,1.32,8.32,8.32,0,0,1-2.37.75l6.7,8.91Zm-1.69-18.4a6.56,6.56,0,0,0-4.12-1.14h-6.75v8.88h6.72a8,8,0,0,0,2.25-.31,5.42,5.42,0,0,0,1.78-.9,3.94,3.94,0,0,0,1.17-1.42,4.22,4.22,0,0,0,.42-1.91A3.82,3.82,0,0,0,315.36,57.58Z"
      transform="translate(-55.33 -27.64)"
    />
    <path
      className="cls-1"
      d="M344.67,76h-3.09L333.2,65.41l-4.64,4.71V76H326.1V54.16h2.46v13l12.52-13h3.18l-9.35,9.54Z"
      transform="translate(-55.33 -27.64)"
    />
  </svg>
);

const NavMenu: FC<{
  collapsed: boolean;
  setCollapsed: (c: boolean) => void;
}> = ({ collapsed, setCollapsed }) => {
  const { token, logout, currentUser, impersonating } = useAuth();
  const isAuthenticated = token != null;

  return (
    <>
      <header className="navbar navbar-light sticky-top bg-white flex-md-nowrap p-0">
        <Link className="navbar-brand" to="/">
          {logo}
        </Link>
        <Navbar className="navbar-expand-md navbar-toggleable-md" light>
          <NavbarToggler
            onClick={() => setCollapsed(!collapsed)}
            className="mr-2"
          />
          <ul className="navbar-nav flex-grow">
            {isAuthenticated && currentUser ? (
              <>
                <NavItem className="d-none d-md-block">
                  <span
                    style={{ display: "block", padding: "0.5rem 1rem" }}
                    className={impersonating ? "bg-danger text-light" : ""}
                  >
                    {currentUser.email}
                  </span>
                </NavItem>

                <NavItem className="d-none d-md-block">
                  <NavLink
                    className="nav-link text-dark ml-sm-5"
                    to="/"
                    onClick={() => logout()}
                  >
                    Sign Out
                  </NavLink>
                </NavItem>
              </>
            ) : (
              <></>
            )}
          </ul>
        </Navbar>
      </header>
    </>
  );
};

export const SideBar: FC<{
  collapsed: boolean;
  setCollapsed: (c: boolean) => void;
}> = ({ collapsed, setCollapsed }) => {
  const { roles, token, logout, impersonating } = useAuth();
  const currentUser = useUser();
  const isAuthenticated = token != null;

  return (
    <nav
      id="sidebarMenu"
      className={
        "col-md-3 col-lg-2 d-md-block bg-light sidebar collapse" +
        (collapsed ? "" : " show")
      }
    >
      <div className="position-sticky">
        <ul className="nav flex-column d-md-none">
          {isAuthenticated ? (
            <>
              <NavItem>
                <span
                  style={{ display: "block", padding: "0.5rem 1rem" }}
                  className={impersonating ? "bg-danger text-light" : ""}
                >
                  {currentUser.email}
                </span>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link text-dark ml-sm-5"
                  to="/"
                  onClick={() => logout()}
                >
                  Sign Out
                </NavLink>
              </NavItem>

              <hr />

              <div
                className="d-block d-md-none"
                style={{ padding: "0rem 1rem" }}
              >
                Version: <BuildVersion />
              </div>

              <hr />
            </>
          ) : (
            <></>
          )}
        </ul>

        <Collapse className="d-md-inline-flex" isOpen={!collapsed} navbar>
          <ul className="nav flex-column">
            <>
              {roles.includes("user:read") && (
                <NavItem>
                  <NavLink className="nav-link text-dark" to="/users">
                    Users
                  </NavLink>
                </NavItem>
              )}

              {roles.includes("company:read") && (
                <NavItem>
                  <NavLink className="nav-link text-dark" to="/companies">
                    Companies
                  </NavLink>
                </NavItem>
              )}

              {roles.includes("site:read") && (
                <NavItem>
                  <NavLink className="nav-link text-dark" to="/sites">
                    Sites
                  </NavLink>
                </NavItem>
              )}

              {roles.includes("site-message:read") && (
                <NavItem>
                  <NavLink className="nav-link text-dark" to="/site-messages">
                    Site Messages
                  </NavLink>
                </NavItem>
              )}

              {roles.includes("site-command:read") && (
                <NavItem>
                  <NavLink className="nav-link text-dark" to="/site-commands">
                    Site Commands
                  </NavLink>
                </NavItem>
              )}

              {roles.includes("parker:read") && (
                <NavItem>
                  <NavLink className="nav-link text-dark" to="/parkers">
                    Parkers
                  </NavLink>
                </NavItem>
              )}

              {roles.includes("reservation:read") && (
                <NavItem>
                  <NavLink className="nav-link text-dark" to="/reservations">
                    Reservations
                  </NavLink>
                </NavItem>
              )}

              {roles.includes("vehicle:read") && (
                <NavItem>
                  <NavLink className="nav-link text-dark" to="/vehicles">
                    Vehicles
                  </NavLink>
                </NavItem>
              )}

              {roles.includes("transaction:read") && (
                <NavItem>
                  <NavLink className="nav-link text-dark" to="/transactions">
                    Transactions
                  </NavLink>
                </NavItem>
              )}

              {roles.includes("report:read") && (
                <NavItem>
                  <NavLink className="nav-link text-dark" to="/reports">
                    Reports
                  </NavLink>
                </NavItem>
              )}

              {roles.includes("status:read") && (
                <NavItem>
                  <NavLink className="nav-link text-dark" to="/status">
                    Status
                  </NavLink>
                </NavItem>
              )}

              {roles.includes("message:read") && (
                <NavItem>
                  <NavLink className="nav-link text-dark" to="/messages">
                    Messages
                  </NavLink>
                </NavItem>
              )}
            </>
          </ul>
        </Collapse>

        <div
          className="fixed-bottom d-none d-md-block"
          style={{ padding: "0.5rem 1rem", width: "150px" }}
        >
          <div>Version:</div>
          <div>
            <BuildVersion />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavMenu;
