import React, { FC } from "react";
import { useAuth } from "./auth/authContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Home } from "./components/Home";
import { Layout } from "./components/Layout";

import { Users } from "./views/Users/Users";
import { User } from "./views/Users/User";
import { Sites } from "./views/Sites/Sites";
import { Site } from "./views/Sites/Site";
import { Login } from "./auth/Login";
import { Companies } from "./views/Companies/Companies";
import { CompanyPage } from "./views/Companies/Company";
// import { Permissions } from "./views/Permissions/Permissions";
import { Permission } from "./views/Permissions/Permission";
import "./custom.css";
import { SiteCommands } from "./views/SiteCommands/SiteCommands";
import { SiteMessages } from "./views/SiteMessages/SiteMessages";
import { Parkers } from "./views/Parkers/Parkers";
import { Parker } from "./views/Parkers/Parker";
import { Reservations } from "./views/Reservations/Reservations";
import { Reservation } from "./views/Reservations/Reservation";
import { Vehicles } from "./views/Vehicles/Vehicles";
import { Vehicle } from "./views/Vehicles/Vehicle";
import { Transactions } from "./views/Transactions/Transactions";
import { Transaction } from "./views/Transactions/Transaction";
import { Permanent } from "./views/Permanent/Permanent";
import { Reports } from "./views/Reports/Reports";
import { Status } from "./views/Status/Status";
import { Product } from "./views/Products/Product";
import { ApolloClientProvider } from "./apolloClientProvider";
import { Messages } from "./views/Messages/Messages";
import { Message } from "./views/Messages/Message";
import { PricePlanEdit } from "./views/PricePlans/PricePlan";

const baseUrl =
  document.getElementsByTagName("base")[0].getAttribute("href") ?? undefined;

function App() {
  return (
    <Router basename={baseUrl}>
      <Layout>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/companies"
            element={
              <PrivateRoute>
                <Companies />
              </PrivateRoute>
            }
          />
          <Route
            path="/companies/:id"
            element={
              <PrivateRoute>
                <CompanyPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/sites"
            element={
              <PrivateRoute>
                <Sites />
              </PrivateRoute>
            }
          />
          <Route
            path="/sites/:id"
            element={
              <PrivateRoute>
                <Site />
              </PrivateRoute>
            }
          />
          <Route
            path="/sites/:id/:tab"
            element={
              <PrivateRoute>
                <Site />
              </PrivateRoute>
            }
          />
          <Route
            path="/sites/:siteId/price-plans/:pricePlanId"
            element={
              <PrivateRoute>
                <PricePlanEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/sites/:siteId/price-plans/:pricePlanId/:tab"
            element={
              <PrivateRoute>
                <PricePlanEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/sites/:siteId/permanent/:parkerId"
            element={
              <PrivateRoute>
                <Permanent />
              </PrivateRoute>
            }
          />
          <Route
            path="/sites/:siteId/permanent/:parkerId/:tab"
            element={
              <PrivateRoute>
                <Permanent />
              </PrivateRoute>
            }
          />
          <Route
            path="/sites/:siteId/products/:productId"
            element={
              <PrivateRoute>
                <Product />
              </PrivateRoute>
            }
          />
          <Route
            path="/site-messages"
            element={
              <PrivateRoute roleRequired="site-message:read">
                <SiteMessages />
              </PrivateRoute>
            }
          />
          <Route
            path="/site-commands"
            element={
              <PrivateRoute roleRequired="site-command:read">
                <SiteCommands />
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute roleRequired="user:read">
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/:id"
            element={
              <PrivateRoute roleRequired="user:read">
                <User />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/:id/:tab"
            element={
              <PrivateRoute roleRequired="user:read">
                <User />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/:id/permissions/:permissionId"
            element={
              <PrivateRoute roleRequired="user:read">
                <Permission />
              </PrivateRoute>
            }
          />
          <Route
            path="/parkers"
            element={
              <PrivateRoute>
                <Parkers />
              </PrivateRoute>
            }
          />
          <Route
            path="/parkers/:parkerId"
            element={
              <PrivateRoute>
                <Parker />
              </PrivateRoute>
            }
          />
          <Route
            path="/parkers/:parkerId/:tab"
            element={
              <PrivateRoute>
                <Parker />
              </PrivateRoute>
            }
          />
          <Route
            path="/parkers/:parkerId/vehicles/:vehicleId"
            element={
              <PrivateRoute>
                <Vehicle />
              </PrivateRoute>
            }
          />
          <Route
            path="/parkers/:parkerId/transactions/:transactionId"
            element={
              <PrivateRoute>
                <Transactions />
              </PrivateRoute>
            }
          />
          <Route
            path="/reservations"
            element={
              <PrivateRoute>
                <Reservations />
              </PrivateRoute>
            }
          />
          <Route
            path="/reservations/:id"
            element={
              <PrivateRoute>
                <Reservation />
              </PrivateRoute>
            }
          />
          <Route
            path="/reservations/:id/:tab"
            element={
              <PrivateRoute>
                <Reservation />
              </PrivateRoute>
            }
          />
          <Route
            path="/vehicles"
            element={
              <PrivateRoute>
                <Vehicles />
              </PrivateRoute>
            }
          />
          <Route
            path="/vehicles/:vehicleId"
            element={
              <PrivateRoute>
                <Vehicle />
              </PrivateRoute>
            }
          />
          <Route
            path="/vehicles/:vehicleId/:tab"
            element={
              <PrivateRoute>
                <Vehicle />
              </PrivateRoute>
            }
          />
          <Route
            path="/transactions"
            element={
              <PrivateRoute>
                <Transactions />
              </PrivateRoute>
            }
          />
          <Route
            path="/transactions/:transactionId"
            element={
              <PrivateRoute>
                <Transaction />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports/:report"
            element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            path="/status"
            element={
              <PrivateRoute roleRequired="status:read">
                <Status />
              </PrivateRoute>
            }
          />
          <Route
            path="/status/:id"
            element={
              <PrivateRoute roleRequired="status:read">
                <Status />
              </PrivateRoute>
            }
          />
          <Route
            path="/messages"
            element={
              <PrivateRoute roleRequired="message:read">
                <Messages />
              </PrivateRoute>
            }
          />
          <Route
            path="/messages/:id"
            element={
              <PrivateRoute roleRequired="message:read">
                <Message />
              </PrivateRoute>
            }
          />
        </Routes>
        <ToastContainer position="top-center" />
      </Layout>
    </Router>
  );
}

const PrivateRoute: FC<{
  children: React.ReactNode;
  roleRequired?: string;
}> = ({ children, roleRequired = null }) => {
  const location = useLocation();
  const { loading, currentUser, roles } = useAuth();
  const isAuthenticated = !loading && currentUser;

  if (!isAuthenticated) {
    return (
      <Navigate
        replace
        to={{ pathname: "/login" }}
        state={{ from: location }}
      />
    );
  }

  // Check if the user has the required role
  if (
    roleRequired &&
    !roles.some((role) => role.startsWith(roleRequired)) &&
    !roles.includes("Parkagility")
  ) {
    return <Navigate replace to={{ pathname: "/" }} />;
  }

  // const hasCompanyRole = roles.some((role) => role.startsWith("Company"));
  // const hasSiteRole = roles.some((role) => role.startsWith("Site"));
  // const hasAreaRole = roles.some((role) => role.startsWith("Area"));
  // const hasAdminRole = roles.some((role) => role.includes("Admin"));

  return <ApolloClientProvider>{children}</ApolloClientProvider>;
};

export default App;
