import React from "react";
import { formatDistanceToNow } from "date-fns";

interface AuditInfoProps {
  entity: {
    createdBy?: string;
    createdUtc?: string | null;
    modifiedBy?: string;
    modifiedUtc?: string | null;
  };
}

const AuditInfo: React.FC<AuditInfoProps> = ({ entity }) => {
  return (
    <div className="mb-3">
      <small>
        <em>
          <span className="text-muted me-2">Created by:</span>
          {entity.createdBy} -{" "}
          <span className="text-muted">
            {formatDistanceToNow(new Date(entity.createdUtc!), {
              addSuffix: true,
            })}
          </span>
          {entity.modifiedBy && entity.modifiedUtc && (
            <span className="ms-4">
              <span className="text-muted me-2">Modified by:</span>
              {entity.modifiedBy} -{" "}
              <span className="text-muted">
                {formatDistanceToNow(new Date(entity.modifiedUtc!), {
                  addSuffix: true,
                })}
              </span>
            </span>
          )}
        </em>
      </small>
    </div>
  );
};

export default AuditInfo;
