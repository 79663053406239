import React, { FC, useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { GetCapacitiesQuery, GetSiteQuery } from "../../gql/graphql";

const CAPACITIES = gql`
  query GetCapacities($siteId: UUID!) {
    capacities(siteId: $siteId) {
      items {
        id
        areaId
        dayOfWeek
        hour
        public
        permanent
        visitor
        total
      }
    }
  }
`;

const UPDATE = gql`
  mutation UpdateCapacities($capacities: [CapacityInput]!) {
    updateCapacities(capacities: $capacities) {
      id
      areaId
      dayOfWeek
      hour
      public
      permanent
      visitor
    }
  }
`;

const dayOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const modes = ["Total", "Public", "Permanent", "Visitor"] as const;
const labelToModes = {
  Total: "total",
  Public: "public",
  Permanent: "permanent",
  Visitor: "visitor",
} as const;

type SiteType = NonNullable<
  NonNullable<GetSiteQuery["sites"]>["items"]
>[number];
type AreaType = NonNullable<NonNullable<SiteType>["areas"]>[number];
type Capacity = NonNullable<
  NonNullable<GetCapacitiesQuery["capacities"]>["items"]
>[number];
type CapacityWithOver = Capacity & { over?: boolean };

export const Capacities: FC<{ site: SiteType }> = ({ site }) => {
  const [area, setArea] = useState<AreaType>(null);
  const { data } = useQuery<GetCapacitiesQuery>(CAPACITIES, {
    variables: { siteId: site?.id },
    fetchPolicy: "network-only",
  }); // , fetchPolicy: 'no-cache'
  const [updateCapacities, { loading: isSubmitting }] = useMutation(UPDATE, {
    refetchQueries: [
      {
        query: CAPACITIES,
        variables: { siteId: site?.id },
        fetchPolicy: "network-only",
      },
    ],
  });
  const [capacities, setCapacities] = useState<CapacityWithOver[]>([]);
  const [capacity, setCapacity] = useState(1);

  // if (data?.capacities)
  // console.log(
  //   data.capacities.items?.filter(
  //     (t) => t?.id === "aa6a46ab-2fcf-4a8f-7eb1-08da2402dc09"
  //   )[0]
  // );

  useEffect(() => {
    if (area && data) {
      const maxCap = area.capacity;
      const caps = structuredClone(
        data?.capacities?.items?.filter((t) => t?.areaId === area.id)
      ) // need to close it to extend with .open property
        ?.map((c) => {
          if (!c) return null;
          c.total = c.public + c.permanent + c.visitor;
          return {
            ...c,
            over: c.total > maxCap,
          };
        })
        .filter(Boolean) as CapacityWithOver[];
      if (caps) {
        setCapacities(caps);
      }
    }
  }, [area, data]);

  useEffect(() => {
    // if only one area, might as well select it by default
    if (site && site.areas?.length === 1) {
      setArea(site.areas[0]);
    }
  }, [site]);

  const cell = (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    id: string | undefined,
    m: (typeof labelToModes)[keyof typeof labelToModes]
  ) => {
    if (e.buttons === 1 && m !== "total" && area) {
      // left click

      const maxCap = area.capacity;
      const ts = capacities; // why is this read only if I do not clone it?

      const temp = ts.find((t) => t.id === id);
      if (temp) {
        temp[m] = capacity;

        temp.total = temp.public + temp.permanent + temp.visitor;
        temp.over = temp.total > maxCap;

        // for now, we'll update all hours of this day/area to be the same capacity
        // var dow = temp.dayOfWeek;

        // ts.filter((t) => t.dayOfWeek === dow && t.areaId === area.id).forEach(
        //   (t) => {
        //     t[m] = capacity;
        //     t.total = t.public + t.permanent + t.visitor;
        //     t.over = t.total > maxCap;
        //   }
        // );
      }

      setCapacities([...ts]);
    }
  };

  const bgColor = (
    cap: CapacityWithOver,
    m: (typeof labelToModes)[keyof typeof labelToModes]
  ) => {
    if (area === null) return "";

    if (cap.over) {
      return "rgba(255, 0, 0, 0.5)";
    }

    var maxCap = area.capacity;
    if (maxCap === 0) return "#eee";
    if (cap === null || !cap[m]) return "#eee";

    var a =
      255 -
      Math.round(((cap.public + cap.permanent + cap.visitor) / maxCap) * 64);
    var mc = 255 - Math.round((cap[m] / maxCap) * 64);

    if (cap[m] > 0) return `rgb(0, ${mc}, 0)`;
    else return `rgb(${a}, ${a}, ${a})`;
  };

  const saveCapacities = () => {
    var caps = capacities.map((c) => {
      delete c.over;
      delete c.__typename;
      return c;
    });

    updateCapacities({ variables: { capacities: caps } }).then((x) => {
      if (x.data.updateCapacities) {
        // console.log(x.data.updateCapacities);

        toast(`capacities updated`, {
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });

        setArea(null);
        setCapacities([]);
      }
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-2">Areas</div>
        <div className="col-md-10">
          {site &&
            site.areas &&
            site.areas.map((a, index) => (
              <span
                key={index}
                className={`btn ${
                  area?.id === a?.id ? "btn-secondary" : "btn-outline-secondary"
                } me-3`}
                onClick={() => setArea(a)}
              >
                {a?.name}
              </span>
            ))}
        </div>
      </div>

      {capacities.length > 0 && (
        <>
          <div className="row g-3 align-items-center mt-5">
            <div className="col-auto">
              <label htmlFor="inputCapacity" className="col-form-label">
                Capacity/Hour
              </label>
            </div>
            <div className="col-auto">
              <input
                type="number"
                min={0}
                step={1}
                id="inputCapacity"
                className="form-control"
                aria-describedby="capacityHelpInline"
                value={capacity}
                onChange={(e) => setCapacity(e.target.valueAsNumber)}
              />
            </div>
            <div className="col-auto">
              <span id="capacityHelpInline" className="form-text">
                Enter the number of bays available for each parker-type each
                hour
              </span>
            </div>
            {/* <div className="col-auto">

                <button type="button" className={`btn btn${mode === 'public' ? '' : '-outline'}-secondary me-1`} onClick={() => setMode('public')}>Public</button>
                <button type="button" className={`btn btn${mode === 'permanent' ? '' : '-outline'}-secondary me-1`} onClick={() => setMode('permanent')}>Permanent</button>
                <button type="button" className={`btn btn${mode === 'visitor' ? '' : '-outline'}-secondary`} onClick={() => setMode('visitor')}>Visitor</button>

            </div> */}
          </div>

          {modes.map((m, index) => (
            <div key={"mode-" + index} className="card mt-5">
              <div className="card-body">
                <h5 className="card-title">{m}</h5>
                <table
                  className="table user-select-none"
                  style={index === 0 ? { opacity: 0.6 } : undefined}
                >
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th colSpan={24} className="text-center">
                        Hour
                      </th>
                    </tr>
                    <tr>
                      <th>Day of Week</th>
                      {Array.from({ length: 24 }, (v, i) => i).map(
                        (h, index) => (
                          <th
                            style={{ textAlign: "center" }}
                            key={"hour-" + index}
                          >
                            {h}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({ length: 7 }, (v, i) => i).map(
                      (dow, index) => (
                        <tr key={dow + "-" + index}>
                          <td>{dayOfWeek[dow]}</td>

                          {Array.from({ length: 24 }, (u, i) => i).map(
                            (h, index) => {
                              const t = capacities.find(
                                (t) => t.dayOfWeek === dow && t.hour === h
                              );

                              return (
                                <td
                                  className="hour-cell"
                                  style={{
                                    backgroundColor:
                                      t && bgColor(t, labelToModes[m]),
                                  }}
                                  onMouseDown={(e) =>
                                    cell(e, t?.id, labelToModes[m])
                                  }
                                  onMouseOver={(e) =>
                                    cell(e, t?.id, labelToModes[m])
                                  }
                                  key={"hour-" + index}
                                >
                                  {t?.[labelToModes[m]] &&
                                  t?.[labelToModes[m]] > 0
                                    ? t?.[labelToModes[m]]
                                    : "-"}
                                </td>
                              );
                            }
                          )}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ))}

          {capacities.length > 0 &&
            capacities.filter((t) => t.over).length > 0 && (
              <div className="alert alert-warning mt-3" role="alert">
                Some hours are over capacity
              </div>
            )}

          <div className="mt-3 mb-3">
            <button
              className="btn btn-primary"
              disabled={
                isSubmitting ||
                capacities.length === 0 ||
                capacities.filter((t) => t.over).length > 0
              }
              onClick={() => saveCapacities()}
            >
              Save
            </button>{" "}
            <button
              className="btn btn-link"
              onClick={() => {
                setArea(null);
                setCapacities([]);
              }}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </>
  );
};
