import { FC, useMemo, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import { CompanyFilterInput, GetCompaniesQuery } from "../../gql/graphql";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ShareParkTable, {
  usePaginationAndFilters,
} from "../../components/table/ShareParkTable";
import { useAuth } from "../../auth/authContext";

export const COMPANIES = gql`
  query GetCompanies($where: CompanyFilterInput, $skip: Int, $take: Int) {
    companies(where: $where, take: $take, skip: $skip) {
      totalCount
      items {
        id
        name
        abn
        address
        isActive #need to select values in where clause
      }
    }
  }
`;

type Company = NonNullable<
  NonNullable<NonNullable<GetCompaniesQuery["companies"]>["items"]>[number]
>;

const columnHelper = createColumnHelper<Company>();

const columns = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: (info) => {
      return (
        <Link to={"/companies/" + info.row.original.id}>
          {info.renderValue()}
        </Link>
      );
    },
  }),
  columnHelper.accessor("abn", {
    header: "Abn",
  }),
  columnHelper.accessor("address", {
    header: "Address",
  }),
];

export const Companies: FC = () => {
  const { roles } = useAuth();
  const { pagination, setPagination, columnFilters, setColumnFilters } =
    usePaginationAndFilters();
  const [showInactive, setShowInactive] = useState(false);

  const where = useMemo(() => {
    const where: CompanyFilterInput = {};
    if (!showInactive) {
      where.isActive = { eq: true };
    }
    for (const filter of columnFilters) {
      switch (filter.id) {
        case "name":
        case "abn":
        case "address":
          if (filter.value != null) {
            where[filter.id] = { contains: String(filter.value) };
          }
          break;
      }
    }
    return where;
  }, [showInactive, columnFilters]);
  const { data, loading } = useQuery<GetCompaniesQuery>(COMPANIES, {
    variables: {
      where: where,
    },
    fetchPolicy: "network-only",
  });

  return (
    <>
      <Loading loading={loading} />

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Companies</h1>
        {roles.includes("company:create") && (
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="btn-group me-2">
              {data && data.companies && (
                <span
                  className={
                    showInactive
                      ? "btn btn-sm btn-secondary"
                      : "btn btn-sm btn-outline-secondary"
                  }
                  onClick={() => setShowInactive(!showInactive)}
                >
                  Inactive
                </span>
              )}
            </div>
            <Link className="btn btn-sm btn-primary ml-2" to="/companies/new">
              Add New
            </Link>
          </div>
        )}
      </div>

      <ShareParkTable
        data={data?.companies ?? { items: [], totalCount: 0 }}
        columns={columns as ColumnDef<Company>[]}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        loading={loading}
        isActiveKey="isActive"
      />
    </>
  );
};
