import React, { FC, useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { TextInput } from "../../components/TextInput";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { AccessTimes } from "../AccessTimes/AccessTimes";
import {
  GetSitesAndSiteParkersQuery,
  SiteParkerFilterInput,
} from "../../gql/graphql";

const GET = gql`
  query GetSitesAndSiteParkers($siteId: UUID, $where: SiteParkerFilterInput) {
    siteParkers(where: $where) {
      items {
        id
        siteId # need to select values in where clause
        areaId
        parkerId # need to select values in where clause
        parker {
          id
          email
          firstName
          lastName
          mobile
          createdUtc
          isActive
        }
        isActive
      }
    }
    sites(where: { id: { eq: $siteId } }) {
      items {
        id
        name
        areas {
          id
          name
        }
      }
    }
  }
`;

const ADD = gql`
  mutation AddPermanentParker($email: String!, $siteId: UUID!) {
    addPermanentParker(email: $email, siteId: $siteId) {
      id
      parker {
        id
        email
        firstName
        lastName
        mobile
        createdUtc
        isActive
      }
      site {
        id
        name
      }
    }
  }
`;

const UPDATE = gql`
  mutation UpdatePermanentSiteParker($siteParker: SiteParkerInput!) {
    updatePermanentParker(siteParker: $siteParker) {
      id
      parker {
        id
        email
        firstName
        lastName
        mobile
        createdUtc
        isActive
      }
      site {
        id
        name
      }
      area {
        id
        name
      }
    }
  }
`;

type SiteParker = NonNullable<
  NonNullable<GetSitesAndSiteParkersQuery["siteParkers"]>["items"]
>[number];
type Parker = NonNullable<SiteParker>["parker"];
type Site = NonNullable<
  NonNullable<GetSitesAndSiteParkersQuery["sites"]>["items"]
>[number];

export const Permanent: FC<{
  parkerId?: string;
  siteId?: string;
}> = ({ parkerId, siteId }) => {
  const { parkerId: pId, siteId: sId, tab } = useParams();
  parkerId = parkerId || pId;
  siteId = siteId || sId;

  const navigate = useNavigate();

  const [site, setSite] = useState<Site>(null);
  const [parker, setParker] = useState<Parker>(null);
  const [siteParker, setSiteParker] = useState<SiteParker>(null);
  const where: SiteParkerFilterInput = {};
  if (siteId) {
    where.siteId = { eq: siteId };
  }
  if (parkerId !== "new") {
    where.parkerId = { eq: parkerId };
  }
  const { data } = useQuery<GetSitesAndSiteParkersQuery>(GET, {
    variables: {
      siteId,
      where,
    },
  });

  const [addPermanentParker, { loading: updating }] = useMutation(ADD); // , { refetchQueries: [ parkerId === 'new' && { query: PARKERS }], awaitRefetchQueries: true }
  const [updatePermanentParker] = useMutation(UPDATE);

  useEffect(() => {
    if (parkerId === "new") {
      setParker({
        id: "00000000-0000-0000-0000-000000000000",
        email: "",
        firstName: "",
        lastName: "",
        mobile: "",
        createdUtc: null,
        isActive: true,
      });
      setSiteParker({
        id: "00000000-0000-0000-0000-000000000000",
        parkerId: parkerId || "00000000-0000-0000-0000-000000000000",
        siteId: siteId || "00000000-0000-0000-0000-000000000000",
        areaId: "00000000-0000-0000-0000-000000000000",
        isActive: true,
      });
    }
    if (data?.siteParkers?.items?.length === 1 && parkerId !== "new") {
      const siteParker = data.siteParkers.items[0];
      if (siteParker) {
        setSiteParker(siteParker);
        if (siteParker.parker) {
          setParker(siteParker.parker);
        }
      }
    }
    if (data?.sites?.items?.length === 1) {
      setSite(data.sites.items[0]);
    }
  }, [data, parkerId, siteId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    if (parkerId === "new") {
      addPermanentParker({
        variables: { email: data.email, siteId: siteId },
      }).then((x) => {
        if (x.data.addPermanentParker) {
          toast(`${x.data.addPermanentParker.parker.email} added`, {
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
          });
          navigate(`/sites/${siteId}/permanent`);
        }
      });
    } else if (siteParker) {
      updatePermanentParker({
        variables: {
          siteParker: {
            id: siteParker.id,
            isActive: siteParker.isActive,
            siteId,
            parkerId: siteParker.parker?.id,
          },
        },
      }).then((x) => {
        if (x.data.updatePermanentParker) {
          toast(`${x.data.updatePermanentParker.parker.email} updated`, {
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
          });
          navigate(`/sites/${siteId}/permanent`);
        }
      });
    }
  };

  return (
    <>
      {parker && siteParker && (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2">
            <h1 className="h2">
              {site && site.name} {parker.email ? " - " + parker.email : ""}
            </h1>
          </div>

          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <Link
                className={`nav-link ${!tab ? "active" : ""}`}
                to={`/sites/${siteId}/permanent/${parker.id}`}
              >
                Permanent Parker
              </Link>
            </li>
            {parker.id !== "00000000-0000-0000-0000-000000000000" && (
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    tab === "access-times" ? "active" : ""
                  }`}
                  to={`/sites/${siteId}/permanent/${parker.id}/access-times`}
                >
                  Access Times
                </Link>
              </li>
            )}
          </ul>

          {!tab && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={
                "needs-validation" +
                (Object.keys(errors).length > 0 ? " was-validated" : "")
              }
              noValidate
            >
              {parkerId === "new" ? (
                <div className="row">
                  <div className="col-md">
                    <TextInput
                      disabled={!!parker.createdUtc}
                      name="email"
                      label="Email"
                      defaultValue={parker.email || ""}
                      placeholder="Email"
                      register={register}
                      config={{
                        required:
                          parker.id === "00000000-0000-0000-0000-000000000000",
                      }}
                      errors={errors}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md">
                      <label htmlFor="email" className="form-label required">
                        Email
                      </label>
                      <div className="input-group mb-3">
                        <Link
                          className="btn btn-outline-secondary"
                          to={`/parkers/${parker.id}`}
                        >
                          Edit Parker
                        </Link>
                        <input
                          className="form-control"
                          type="text"
                          disabled={!!parker.createdUtc}
                          name="email"
                          defaultValue={parker.email || ""}
                          placeholder="Email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md">
                      <TextInput
                        disabled={true}
                        name="firstName"
                        label="First Name"
                        defaultValue={parker.firstName || ""}
                        placeholder="First Name"
                        register={register}
                        config={{ required: false }}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md">
                      <TextInput
                        disabled={true}
                        name="lastName"
                        label="Last Name"
                        defaultValue={parker.lastName || ""}
                        placeholder="Last Name"
                        register={register}
                        config={{ required: false }}
                        errors={errors}
                      />
                    </div>
                    <div className="col-md">
                      <TextInput
                        disabled={true}
                        name="mobile"
                        label="Mobile"
                        defaultValue={parker.mobile || ""}
                        placeholder="Mobile"
                        register={register}
                        config={{ required: false }}
                        errors={errors}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col-md">
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="btn-check-outlined"
                    autoComplete="off"
                    onClick={() =>
                      setSiteParker({
                        ...siteParker,
                        isActive: !siteParker.isActive,
                      })
                    }
                  />
                  <label
                    className={
                      siteParker.isActive ? "btn btn-success" : "btn btn-danger"
                    }
                    htmlFor="btn-check-outlined"
                  >
                    {siteParker.isActive ? "Active" : "Inactive"}
                  </label>
                </div>
              </div>

              <div className="mt-3 mb-3">
                <input
                  disabled={updating}
                  className="btn btn-primary"
                  type="submit"
                  value="Save"
                />{" "}
                <Link
                  to={`/sites/${siteId}/permanent`}
                  className="btn btn-link"
                >
                  Cancel
                </Link>
              </div>
            </form>
          )}

          {tab === "access-times" && site && (
            <AccessTimes
              siteParkerId={siteParker.id}
              returnUrl={`/sites/${siteId}/permanent/${parkerId}`}
              site={site}
            />
          )}
        </>
      )}
    </>
  );
};
