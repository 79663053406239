import React from "react";
import { useQuery, gql } from "@apollo/client";
import { GetMessageQuery } from "../../gql/graphql";
import { useParams } from "react-router-dom";
import { ShareparkDateTime } from "../../components/LocalTime";

const GET_MESSAGES = gql`
  query GetMessage($id: UUID!) {
    messages(where: { id: { eq: $id } }) {
      items {
        id
        parkerId
        userId
        recipient
        content
        messageType
        sentTime
        isSent
      }
    }
  }
`;

export const Message = () => {
  let { id } = useParams();

  const { loading, data } = useQuery<GetMessageQuery>(GET_MESSAGES, {
    variables: {
      id: id,
    },
    fetchPolicy: "network-only",
  });

  const content =
    data?.messages?.items &&
    data?.messages?.items[0]?.content &&
    data?.messages?.items[0]?.content
      .replace("cid:logo", "/images/sharepark-logo.svg")
      .replace("cid:qrcode", "/images/sharepark-qrcode.png");

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Message</h1>

        {/* <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <span className="btn btn-sm btn-outline-secondary">Reply</span>
          </div>
        </div> */}
      </div>

      {data &&
        data.messages &&
        data.messages.items &&
        data.messages.items.length > 0 && (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">
                      {data?.messages?.items[0]?.recipient || "Unknown"}
                    </h5>
                    <div className="card-subtitle text-muted">
                      <ShareparkDateTime
                        timestamp={data?.messages?.items[0]?.sentTime}
                      />
                    </div>
                  </div>
                  <div className="card-body">
                    {data?.messages?.items[0]?.messageType === "Email" && (
                      <div className="card-text">
                        {content ? (
                          <div dangerouslySetInnerHTML={{ __html: content }} />
                        ) : (
                          <p>No content available</p>
                        )}
                      </div>
                    )}

                    {data?.messages?.items[0]?.messageType === "SMS" && (
                      <div className="card-text">
                        <div
                          className="whitespace-pre-line"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {content}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};
