import { FC, useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { TextInput } from "../../components/TextInput";
import { gql, useMutation, useQuery } from "@apollo/client";
import { COMPANIES } from "./Companies";
import { toast } from "react-toastify";
import { AddressFinder } from "../../components/AddressFinder";
import {
  Company,
  GetCompanyQuery,
  GetCompanyQueryVariables,
} from "../../gql/graphql";
import AuditInfo from "../../components/AuditInfo";
import { useAuth } from "../../auth/authContext";

const GET = gql`
  query GetCompany($id: UUID!) {
    companies(where: { id: { eq: $id } }) {
      items {
        id
        name
        abn
        address
        addressMetadata
        isActive
        comments
        createdUtc
        createdBy
        modifiedUtc
        modifiedBy
      }
    }
  }
`;

const UPDATE = gql`
  mutation UpdateCompany($company: CompanyInput!) {
    updateCompany(company: $company) {
      id
      name
      abn
      address
      addressMetadata
      isActive
      comments
      createdUtc
      createdBy
      modifiedUtc
      modifiedBy
    }
  }
`;
export const CompanyPage: FC = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { roles } = useAuth();

  const [company, setCompany] = useState<Partial<Company> | null>(null);
  const { data } = useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GET, {
    variables: {
      id: id === "new" || !id ? "00000000-0000-0000-0000-000000000000" : id,
    },
  });

  const [updateCompany, { loading: updating }] = useMutation<
    {
      updateCompany: Company;
    },
    {
      company: Company;
    }
  >(UPDATE, {
    refetchQueries: id === "new" ? [{ query: COMPANIES }] : [],
    awaitRefetchQueries: true,
  }); // [ updateCompany, { data, loading, error }]

  useEffect(() => {
    if (id === "new") {
      setCompany({
        id: "00000000-0000-0000-0000-000000000000",
        name: "",
        abn: "",
        isActive: true,
      });
    }
    const [company] = data?.companies?.items ?? [];
    if (company) {
      setCompany(company);
    }
  }, [data, id]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    updateCompany({
      variables: {
        company: {
          ...(data as Company),
          id: company?.id!,
          addressMetadata: company?.addressMetadata!,
          isActive: company?.isActive!,
        },
      },
    }).then((x) => {
      if (x.data?.updateCompany) {
        toast(`${x.data?.updateCompany.name} saved`, {
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
        navigate("/companies");
      }
    });
  };

  return (
    <>
      {company && (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
            <h1 className="h2">Company</h1>
          </div>
          <AuditInfo
            entity={
              company as {
                createdBy?: string;
                createdUtc?: string | null;
                modifiedBy?: string;
                modifiedUtc?: string | null;
              }
            }
          />

          <form
            onSubmit={handleSubmit(onSubmit)}
            className={
              "needs-validation" +
              (Object.keys(errors).length > 0 ? " was-validated" : "")
            }
            noValidate
          >
            <div className="row">
              <div className="col-md">
                <TextInput
                  name="name"
                  label="Name"
                  defaultValue={company?.name ?? ""}
                  placeholder="Company Name"
                  register={register}
                  config={{ required: true }}
                  required={true}
                  errors={errors}
                  readOnly={!roles.includes("company:edit")}
                />
              </div>
              <div className="col-md">
                <TextInput
                  name="abn"
                  label="ABN"
                  defaultValue={company?.abn ?? ""}
                  placeholder="Company ABN"
                  register={register}
                  required={false}
                  errors={errors}
                  readOnly={!roles.includes("company:edit")}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <AddressFinder
                  enabled={!!company}
                  onAddressSelect={(fullAddress, metaData) => {
                    setCompany({
                      ...company,
                      addressMetadata: JSON.stringify(metaData),
                    });
                    setValue("address", fullAddress);
                  }}
                  onLocationSelect={(fullLocation, metaData) => {
                    setCompany({
                      ...company,
                      addressMetadata: JSON.stringify(metaData),
                    });
                  }}
                  id="address"
                  name="address"
                  label="Address"
                  defaultValue={company?.address ?? ""}
                  placeholder="Address"
                  register={register}
                  required={false}
                  errors={errors}
                  readOnly={!roles.includes("company:edit")}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <label htmlFor="comments">Comments</label>
                <textarea
                  rows={8}
                  className="form-control"
                  defaultValue={company?.comments ?? ""}
                  {...register("comments")}
                  placeholder="Company contact details, etc..."
                  readOnly={!roles.includes("company:edit")}
                ></textarea>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="btn-check-outlined"
                  autoComplete="off"
                  onClick={() =>
                    roles.includes("company:edit") &&
                    setCompany({ ...company, isActive: !company.isActive })
                  }
                />
                <label
                  className={
                    company.isActive ? "btn btn-success" : "btn btn-danger"
                  }
                  htmlFor="btn-check-outlined"
                >
                  {company.isActive ? "Active" : "Inactive"}
                </label>
              </div>
            </div>

            {roles.includes("company:edit") && (
              <div className="mt-3 mb-3">
                <input
                  disabled={updating}
                  className="btn btn-primary"
                  type="submit"
                  value="Save"
                />{" "}
                <Link to={"/companies"} className="btn btn-link">
                  Cancel
                </Link>
              </div>
            )}
          </form>
        </>
      )}
    </>
  );
};
