import React from "react";
import {
  FieldErrors,
  FieldPath,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";

const asString = (msg: unknown): string => {
  if (!msg) {
    return "";
  } else if (typeof msg === "string") {
    return msg;
  } else if (typeof msg === "object" && "message" in msg) {
    return asString(msg.message);
  } else {
    return "";
  }
};

function TextInputInner<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  {
    name,
    label,
    placeholder,
    register,
    defaultValue,
    errors,
    config,
    ...rest
  }: {
    name: TFieldName;
    label: string;
    register: UseFormRegister<TFieldValues>;
    errors: FieldErrors<TFieldValues>;
    config?: RegisterOptions<TFieldValues>;
  } & React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  forwardRef: React.ForwardedRef<HTMLInputElement>
) {
  let required =
    config?.required ||
    (errors[name]?.type === "validate" && errors[name]?.message !== "")
      ? true
      : false;
  const registerProps = register(name, config);
  return (
    <div className="mb-3">
      <label
        htmlFor={name}
        className={"form-label " + (required ? "required" : "")}
      >
        {label}
      </label>
      <input
        id={name}
        className="form-control"
        defaultValue={defaultValue}
        {...registerProps}
        ref={(instance) => {
          if (forwardRef) {
            if ("call" in forwardRef) {
              forwardRef(instance);
            } else {
              forwardRef.current = instance;
            }
          }
          registerProps.ref(instance);
        }}
        name={name}
        placeholder={placeholder}
        required={required}
        autoComplete="off"
        {...rest}
      />

      {required === true ? (
        <div className="invalid-feedback">
          {asString(errors[name]?.message) || `Please enter a valid ${label}`}
        </div>
      ) : (
        errors[name] && (
          <div className="invalid-feedback">
            {typeof errors[name]?.message === "string"
              ? asString(errors[name]?.message)
              : null}
          </div>
        )
      )}
    </div>
  );
}

export const TextInput = React.forwardRef(TextInputInner);
