import { initializeApp } from "firebase/app";
import { EmailAuthProvider, GoogleAuthProvider } from "firebase/auth";

export const config = {
  apiKey: "AIzaSyCyZInaizgxhtsdLHkpyqt4hzwqa2VI8cc",
  authDomain: "auth.sharepark.net",
  projectId: "sharepark-251906",
  storageBucket: "sharepark-251906.appspot.com",
  messagingSenderId: "417484639508",
  appId: "1:417484639508:web:12046e9bf0bed82cfec601",
  measurementId: "G-TM2PND970H",
};

export const uiConfig = {
  signInFlow: "popup",

  signInSuccessUrl: "/",

  signInOptions: [
    {
      provider: "microsoft.com",
      requireDisplayName: true,
      customParameters: {
        prompt: "select_account",
      },
    },
    GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // 'apple.com',
    {
      provider: EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
      signInMethod: EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
    },
  ],
};

const app = initializeApp(config);

export default app;
